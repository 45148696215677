import React from "react"
import { Helmet } from "react-helmet"

import Layout from "@components/layout"
import { Link } from "gatsby"

export default function Beta() {
  
  return (
    <main>
      <Helmet>
        <html lang="en" />
        <title>FasTrack: Not Found</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <Layout nobg>

        <div className="flex pt-48 items-center text-center">
          <h1 className="mb-8 text-2xl">Not Found</h1>
          <p className="mb-4">Sorry, this page could not be found.</p>
          <p><Link to="/" className="text-blue-500 underline">Go back to Homepage</Link></p>
        </div>

      </Layout>
      
    </main>
  )
}
