import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import Logo from "../components/logo.js"
import VideoBox from "@components/videobox.js"
import { Helmet } from "react-helmet"

export default function Layout({ children, nobg }) {

  const headerStyles = css`
    background-color: ${nobg ? 'black' : 'transparent'};
    position: ${nobg ? 'relative' : 'absolute'};
  `

  const linkClasses = `inline-block p-1 mb-2 md:mb-0 mx-2 lg:mx-2 bg-black-alpha-600 text-sm text-white transition duration-300 hover:bg-black-alpha-800`

  const menuLinks = [
    {
      label: 'Features',
      url: '/#features',
      classes: [],
    },
    {
      label: 'Documentation',
      url: '/#documentation',
      classes: [],
    },
    {
      label: 'Contact Us',
      url: '/#contact-us',
      classes: [],
    },
    {
      label: 'Manage Subscription',
      url: '/manage-subscription',
      classes: []
    }
  ]

  let video = React.createRef();

  const openReel = () => {
    video.open();
  };

  return (
    <div className="h-full flex flex-col">
      <Helmet>
        <script>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '190191782754361'); 
          fbq('track', 'PageView');
        `}
        </script>
        <noscript>
        {`
          <img height="1" width="1" 
          src="https://www.facebook.com/tr?id=190191782754361&ev=PageView
          &noscript=1"/>
        `}
        </noscript>
        <script type="text/javascript">
        {`
          (function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://cdn.firstpromoter.com/fprom.js',t.onload=t.onreadystatechange=function(){var t=this.readyState;if(!t||"complete"==t||"loaded"==t)try{$FPROM.init("797jmzz5",".gofox.ai")}catch(t){}};var e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)})();
        `}
        </script>
      </Helmet>

      <header css={headerStyles} className="absolute w-full top-0 left-0 z-30 py-4 md:py-8">
        <div className="px-4 md:px-0 container flex flex-col justify-center md:flex-row md:justify-between md:items-center">
          <div className="flex items-center justify-center mb-4 md:mb-0">
            <Logo />
          </div>
          <nav className="mb-4 md:mb-0">
            <ul className="list-none flex flex-wrap justify-center">

              <li>
                <button className={linkClasses}
                  onClick={openReel}>Watch the Reel</button></li>
              {menuLinks.map( (link, index) => {
                return (
                  <li key={index}>
                    { link.external
                    ? (<a className={`${linkClasses} ${link.classes.join(' ')}`} href={link.url}>{link.label}</a>)
                    : (<Link className={`${linkClasses} ${link.classes.join(' ')}`} to={link.url}>{link.label}</Link>)
                    }
                  </li>)
              })}
            </ul>
          </nav>
        </div>
      </header>

      <div className="">
        { children }
      </div>

      <VideoBox ref={(elem) => video = elem}/>

      <footer className="mt-auto bg-blue-500 text-sm text-center py-3">
        <p className="text-black-alpha-800 leading-none">&copy; 2020 GOFOX Ai. All Rights Reserved.</p>
      </footer>

    </div>
  )

}