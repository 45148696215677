import React, { Component, Fragment } from "react"

export default class VideoBox extends Component {

  constructor(props) {
    super(props)
    this.state = {
      'showOverlay': false,
    }
  }

  open() {
    this.setState({ 'showOverlay': true })
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      this.setState({ 'showOverlay': false })
    }
  }

  render() {
    const { showOverlay } = this.state
    return (
      <Fragment>
        {showOverlay && (
        <div className="fixed top-0 left-0 w-full h-full z-50"
          style={{'backgroundColor': 'rgba(0, 0, 0, 0.5)'}}
          role="button"
          tabIndex="0"
          onClick={() => this.setState({ 'showOverlay': false }) }
          onKeyPress={ this.handleKeyPress }
          >
          <div className="w-full h-full flex items-center justify-center">
            <div className="container">
              <div className="relative bg-black shadow-2xl" style={{'paddingTop': '56.25%'}}>
                <iframe title="FasTrack Beta Teaser" className="absolute w-full h-full top-0 left-0" src="https://www.youtube.com/embed/dB-uqZfVDU8?autoplay=1" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
      </Fragment>
    )
  }
}